import React from "react";
import styled from "styled-components";
import TransHTML from "~components/transHTML";
import LinkExternal from "~components/link-external";
import {smallScreenBreakpoint1064px, tabletBreakpoint} from "~styles/common";
import {RatingByStars} from "~components/reviews/stars";
import {MerchantReviewItemType} from "~components/reviews/types";

type PropTypes = {
    item: MerchantReviewItemType;
}

export function MerchantReviewItem({ item }: PropTypes) {
    const hasSomeSite = null !== item.instLink || null !== item.fbLink;
    return (
        <Wrapper key={item.id}>
            <Owner>
                <UserImg img={item.userImg} />
                <NameBox>
                    <UserName>
                        {item.userName}
                    </UserName>
                    <UserLastName>
                        <TransHTML id={item.userJob} />
                    </UserLastName>
                </NameBox>
            </Owner>
            <Text>
                <TransHTML id={item.userComment} />
            </Text>
            <Bottom>
                <RatingByStars review={item.stars} />
                {hasSomeSite && (
                    <Socials>
                        {item.instLink && (
                            <LinkExternal href={item.instLink}>
                                <SocialIco src="/static/images/home-page/icons/instagram.svg" alt="Instagram" />
                            </LinkExternal>
                        )}
                        {item.fbLink && (
                            <LinkExternal href={item.fbLink}>
                                <SocialIco src="/static/images/home-page/icons/facebook.svg" alt="Facebook" />
                            </LinkExternal>
                        )}
                    </Socials>
                )}
            </Bottom>
        </Wrapper>
    );
}

const SocialIco = styled.img`
    width: 25px;
    height: 25px;
    transition: filter .3s;

    &:hover{
        filter: opacity(0.5);
    }
`;

const Socials = styled.div`
    display: flex;
    gap: .9rem;
    max-width: 58px !important;

    svg {
        width: 26px;
        height: 26px;
    }
`;

const Owner = styled.div`
    display: flex;
    align-items: center;
    gap: .9rem;
`;

const UserName = styled.h3`
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0;
`;

const NameBox = styled.div`
`;

const UserLastName = styled.span`
    font-size: .9rem;
    font-weight: 500;
    color: #878787;
`;

const Bottom = styled.div`
    display: flex ;
    justify-content: space-between;
`;

const UserImg = styled.div`
    background: url(${({ img }) => img}) no-repeat;
    width: 41px;
    height: 42px;
    background-size: contain;
    border-radius: 50%;
`;

// todo: fix margin!
const Wrapper = styled.div`
    background: #FAFAFA;
    border-radius: 16px;
    padding: 1rem;
    margin: 0 .5rem;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 2rem;
        max-width: none;
        min-height: 382px;
        gap: 0;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        margin: 0 2rem;
        padding: 2.5rem;
        max-width: 400px;
    }
`;

const Text = styled.div`
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.5;
    width: 100%;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
    }
`;
