import { t } from '@lingui/macro';
import {BenefitType} from "~components/features/types";

export const featuresForMerchants: BenefitType[] = [
    {
        text: t`home.page.why.trisbee.benefit1.title`,
        subText: t`home.page.why.trisbee.benefit1.subtitle`,
        icon: '/static/images/home-page/icons/heart3.svg'
    },
    {
        text: t`home.page.why.trisbee.benefit2.title`,
        subText: t`home.page.why.trisbee.benefit2.subtitle`,
        icon: '/static/images/home-page/icons/phone.svg'
    },
    {
        text: t`home.page.why.trisbee.benefit3.title`,
        subText: t`home.page.why.trisbee.benefit3.subtitle`,
        icon: '/static/images/home-page/icons/art.svg'
    },
    {
        text: t`home.page.why.trisbee.benefit4.title`,
        subText: t`home.page.why.trisbee.benefit4.subtitle`,
        icon: '/static/images/home-page/icons/flash-payment.svg'
    },
]
