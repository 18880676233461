import React from 'react';
import Layout from '../src/components/layout/_layout';
import PageTypes from '~/page_types.ts';
import { t } from "@lingui/macro";
import SEO from "../src/components/seo";
import Homepage from "../src/components/homepage/Homepage";
import WhyBusiness from '../src/components/homepage/why-business';
import FadeInSection from '~/components/fadeinSection';
import HowItsWorksSection from '../src/components/homepage/HowItsWorksSection';
import EasyControl from '../src/components/homepage/EasyControl';
import SelectPayment from '../src/components/homepage/SelectPayment';
import BusinessSection from '../src/components/homepage/BusinessSection';
import JoinUsSection from '../src/components/homepage/JoinUsSection';
import HaveAQuestion from '~/components/homepage/haveAQuestion';
import ReferenceSection from '../src/components/homepage/reference';

/**
 * Main home page of the website
 * User will se intersection with: personal/business
 */

const pageSections = [
    <Homepage />,
    <WhyBusiness />,
    <HowItsWorksSection />,
    <SelectPayment />,
    <EasyControl />,
    <BusinessSection />,
    <JoinUsSection />,
    <ReferenceSection />,
    <HaveAQuestion whiteBg='white' />,
];

function IndexPage() {
    return (
        <Layout welcome={true} page={PageTypes.INDEX}>
            <SEO
                title={t`page.index.seo.title`}
                description={t`page.index.seo.description`}
            />
            {pageSections.map((section, id) => {
                return <FadeInSection key={id}>{section}</FadeInSection>;
            })}
        </Layout>
    );
}

export default IndexPage;
