import React from 'react';
import TransHTML from '~/components/transHTML';
import styled from 'styled-components';
import { smallScreenBreakpoint1064px, tabletBreakpoint } from '~/styles/common';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

const EasyControl = ({ i18n }) => (

    <HowItsWorksWrapper>
        <HowItsWorksContainer>

            <ImgContainer>
                <ImgBlock alt='Easy Control' src={i18n._(t`home.page.easyControl.image`)} />
                <Icon alt='Easy Control' src={i18n._(t`home.page.easyControl.icon`)} />
            </ImgContainer>

            <HowItsWorksText>
                <Heading>
                    <TransHTML id={t`home.page.easy.control.title`}/>
                </Heading>

                <SubHeading>
                    <TransHTML id={t`home.page.easy.control.text`}/>
                </SubHeading>
            </HowItsWorksText>
        </HowItsWorksContainer>
    </HowItsWorksWrapper>

);

export default withI18n()(EasyControl);

// todo: fix padding declarations
const HowItsWorksWrapper = styled.section`
    padding: 1.5rem 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 1rem;
    }
`;

const HowItsWorksText = styled.div`
    @media (min-width: ${tabletBreakpoint}) {
        padding-top: 5rem;
        width: 80%;
    }
`;

const ImgContainer = styled.div`
    margin: 0 auto;
    position: relative;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (min-width: ${tabletBreakpoint}) {
        display: block;
    }
`;

const HowItsWorksContainer = styled.div`
    display: flex;
    gap: 2rem;
    max-width: 990px;
    margin: 0 auto;
    flex-direction: column;

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        flex-direction: unset;
        align-items: flex-start;
    }
`;

const ImgBlock = styled.img`
    object-fit: contain;
    max-width: 200px;
    display: block;
    margin-left: 30%;

    @media (min-width: ${tabletBreakpoint}) {
        min-width: 100%;
        height: 550px;
        margin-left: 0;
    }
`;

const Icon = styled.img`
    width: 213px;
    transform: translate(-98px, 92px);
    
    @media (min-width: ${tabletBreakpoint}) {
        position: absolute;
        bottom: -1%;
        left: 41%;
        width: unset;
        transform: unset;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        bottom: -1%;
        left: -3%;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: 2rem;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: 400px;
        text-align: left;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-size: 1rem;
    font-weight: 400;

    @media (min-width: ${tabletBreakpoint}) {
        font-size: 1.2rem;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: 400px;
        text-align: left;
    }
`;





