import React, { useContext } from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { FHDBreakpoint1920px, smallScreenBreakpoint1064px, tabletBreakpoint, mobileBreakpoint } from '~/styles/common';
import TransHTML from '~/components/transHTML';
import { withI18n } from '@lingui/react';
import { t } from '@lingui/macro';

function BusinessSection({ i18n }) {

    return (
        <BusinessSectionWrapper>

            <Ico alt='Trisbee Business' src={'/static/images/home-page/business-ico.svg'} />

            <TopHeading>
                <Trans id='seo.business.og_site_name' />
            </TopHeading>

            <ImgBlock alt='Trisbee Business' src={i18n._(t`home.page.businessSection.image`)} />

            <ImgPhone alt='Trisbee Business' src={i18n._(t`home.page.businessSectionPhone.image`)} />

            <SubHeading>
                <TransHTML id={t`home.page.trisbee.business.text`} />
            </SubHeading>

        </BusinessSectionWrapper>
    );
}

export default withI18n()(BusinessSection);

const BusinessSectionWrapper = styled.section`
    padding: 2rem 1rem;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @media (min-width: ${tabletBreakpoint}) {
        gap: 2.5rem;
    }  

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        padding: 4rem 1rem;
    }  
`;

const ImgBlock = styled.img`
    display: none;
  
    @media (min-width: ${tabletBreakpoint}) {
        display: block;
        max-width: 100%;
        max-width: 700px;
        object-fit: contain;
        margin-bottom: 1rem;
    }
`;

const ImgPhone = styled.img`
    display: block;
    margin-bottom: 1rem;
    object-fit: contain;
    max-width: 200px;
  
    @media (min-width: ${tabletBreakpoint}) {
        display: none;
    }
`;

const Ico = styled.img`
    object-fit: contain;
`;

const TopHeading = styled.h2`
    font-weight: 700;
    font-size: 2rem;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 2.5rem;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;

    @media (min-width: ${tabletBreakpoint}) {
        font-size: 1.3rem;
        max-width: 540px;
        padding-right: 1rem;
        font-weight: 400;
    }
`;




