import React, { useState, useEffect } from 'react';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import styled from 'styled-components'
import { tabletBreakpoint, smallScreenBreakpoint1064px } from '~/styles/common';
import InPageAnchor from "../../InPageAnchor";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { featuresForMerchants } from "~components/features/data-merchants";
import { anchors } from "../../../../shared/routing";
import {Partners} from "~components/homepage/why-business/partners";
import {FeatureCarouselItem} from "~components/features/carousel-item";
import {partnerData} from "~components/homepage/why-business/partner-data";

function WhyBusiness() {
    const [centerSlide, setCenterSlider] = useState(45);
    const [isMobile, setIsMobile] = useState(true);
    const [showArrows, setShowArrows] = useState(true);

    // todo: move to hook?
    useEffect(() => {
        const getResize = () => {
            if (window.innerWidth <= 550) {
                setCenterSlider(100)
                setIsMobile(false)
                setShowArrows(false)
            } else if (window.innerWidth <= 1064) {
                setCenterSlider(50)
                setShowArrows(false)
            }
        }

        getResize()

        window.addEventListener('resize', getResize)
        return () => window.removeEventListener('resize', getResize)

    }, [])

    // todo: this looks duplicate
    const arrowStyles = {
        position: 'absolute',
        zIndex: 51,
        top: 'calc(50% - 15px)',
        width: 56,
        height: 56,
        cursor: 'pointer',
        padding: 10,
    };

    // todo: this looks duplicate
    const indicatorStyles = {
        background: '#D9D9D9',
        width: 9,
        height: 9,
        display: 'inline-block',
        margin: '0 8px',
    };

    // todo: this looks duplicate
    const getConfigurableProps = () => ({
        showIndicators: true,
        infiniteLoop: true,
        stopOnHover: true,
        swipeable: true,
        emulateTouch: true,
        thumbWidth: 100,
        selectedItem: 1,
        interval: 2000,
        transitionTime: 500,
    });

    // todo: create reusable component from the carousel and replace usages
    return (
        <>
            <InPageAnchor id={anchors.business.benefits} />
            <Wrapper>
                <Headline>
                    <TransHTML id={t`home.page.why.trisbee.title`} />
                </Headline>
                <Carousel
                    infiniteLoop
                    centerSlidePercentage={centerSlide}
                    centerMode={isMobile}
                    {...getConfigurableProps()}
                    showStatus={false}
                    showThumbs={false}
                    showArrows={showArrows}
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <Next onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 240 }}>
                                <RightArrow alt='Right arrow' src={'/static/images/carousel/arrow-right.svg'} />
                            </Next>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <Prev onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 260 }}>
                                <LeftArrow alt='Left arrow' src={'/static/images/carousel/arrow-left.svg'} />
                            </Prev>
                        )
                    }
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                        if (isSelected) {
                            return (
                                <Span
                                    style={{ ...indicatorStyles, background: '#0CC8A8' }}
                                    aria-label={`Selected: ${label} ${index + 1}`}
                                    title={`Selected: ${label} ${index + 1}`}
                                />
                            );
                        }
                        return (
                            <Span
                                style={indicatorStyles}
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                                role="button"
                                tabIndex={0}
                                title={`${label} ${index + 1}`}
                                aria-label={`${label} ${index + 1}`}
                            />
                        );
                    }}
                >
                    {featuresForMerchants.map((sliderItem) => (
                        <FeatureCarouselItem
                            key={sliderItem.text}
                            item={sliderItem}
                        />
                    ))}
                </Carousel>
                <Partners
                    data={partnerData}
                />
            </Wrapper>
        </>
    );
}

export default WhyBusiness;

const Span = styled.span`
    width: 10px;
    height: 10px;
    background: #D9D9D9;
    display: block;
    border-radius: 50px;

    &.active {
        background-color: #0CC8A8;
    }
`;

const Wrapper = styled.div`
    padding: 1.5rem 0;
    overflow: hidden;
    max-width: 1063px;
    margin: 0 auto;

    .control-dots{
        transform: translateY(53px);
    }

    .carousel.carousel-slider{
        overflow: unset;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        .slider-wrapper {
            position: relative;

        &::before{
            content: '';
            position: absolute;
            background: linear-gradient(270deg, #FFFFFF 52.53%, rgba(255, 255, 255, 0.84) 95.9%);
            border-radius: 15px;
            right: -90px;
            width: 413px;
            height: 420px;
            z-index: 50;
        }

        &::after {
            content: '';
            position: absolute;
            background: linear-gradient(270deg, #FFFFFF 52.53%, rgba(255, 255, 255, 0.84) 95.9%);
            border-radius: 15px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            left: -110px;
            width: 413px;
            height: 420px;
            top: -4px;
        }
    }
}

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 0;
    }
`;

const Prev = styled.div`
    border: 1px solid black;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .5s;

    &:hover{
        background-color: #f5f3fb;
    }
`;

const Next = styled.div`
    border: 1px solid black;
    border-radius: 50px;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .5s;
    z-index: 55;

    &:hover{
       background-color: #f5f3fb;
    }
`;

const RightArrow = styled.img`
    width: 50% !important;
`;

const LeftArrow = styled.img`
    width: 50% !important;
`;

const Headline = styled.h2`
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3.5rem;
    font-weight: 700;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        line-height: 1.2;
    }
`;
