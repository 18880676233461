import React, { useContext } from 'react';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { FHDBreakpoint1920px, smallScreenBreakpoint1064px, tabletBreakpoint, mobileBreakpoint } from '~/styles/common';
import { withI18n } from '@lingui/react';

const SelectPayment = ({ i18n }) => (

    <SelectPaymentWrapper>
        <SelectPaymentContainer>
            <SelectPaymentText>
                <Heading>
                    <TransHTML id={t`home.page.select.payment.title`}/>
                </Heading>

                <SubHeading>
                    <TransHTML id={t`home.page.select.payment.text`}/>
                </SubHeading>
                <Container>
                    <Icon alt='Apple Pay' src='/static/images/home-page/payments/ap-pay.svg' />
                    <Icon alt='Google Pay' src='/static/images/home-page/payments/gpay.svg' />
                    <Icon alt='Mastercard' src='/static/images/home-page/payments/mcard.svg' />
                    <Icon alt='Visa' src='/static/images/home-page/payments/visa.svg' />
                </Container>
            </SelectPaymentText>

            <ImgBlock alt='Trisbee app' src={i18n._(t`home.page.selectPayment.image`)} />
        </SelectPaymentContainer>
    </SelectPaymentWrapper>
);

export default withI18n()(SelectPayment);

const Container = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
    order: -1;
    margin: 2rem 0;

    @media (min-width: ${tabletBreakpoint}) {
        justify-content: flex-start;
        transform: translateY(30px);
        order: 0;
        margin: 0;
    }
`;

const Icon = styled.img`
    width: 48px;

    @media (min-width: ${tabletBreakpoint}) {
        width: 50px;
    }
    
    @media (min-width: ${smallScreenBreakpoint1064px}) {
        width: unset;
    }
`;

const SelectPaymentWrapper = styled.section`
    padding: 1.5rem 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 1rem;
    }
`;

const SelectPaymentText = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (min-width: ${tabletBreakpoint}) {
        margin-top: -3rem;
        max-width: 264px;
    }
   
    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: unset;
    }
`;

// todo: fix margin, padding declarations
const SelectPaymentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    align-items: center;
    max-width: 990px;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 0.5rem;
    padding: 2rem 1rem;
    margin: 0 auto;
    position: relative;

    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #FAFAFA;
        z-index: -1;
        border-radius: 1rem;

        @media (min-width: ${tabletBreakpoint}) {
            bottom: 85px;
            top: 85px;
        }
    }

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
        align-items: center;
        padding: 3rem;
        padding-right: 0;
    }
`;

const ImgBlock = styled.img`
   width: 100%;
   height: 400px;
   object-fit: contain;

  
   @media (min-width: ${tabletBreakpoint}) {
        background-position-x: right;
        height: 560px;
        margin-bottom: 0;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
 
    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 2rem;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2rem;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 400px;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
`;




