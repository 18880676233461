import React, { useContext } from 'react';
import { Trans } from '@lingui/react';
import { Section } from '~/styles/welcome';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { LangContext } from '~/components/../providers/LangProvider';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import styled from 'styled-components';
import { FHDBreakpoint1920px, smallScreenBreakpoint1064px, tabletBreakpoint, mobileBreakpoint } from '~/styles/common';
import { YellowButton, BlackTransparentButton } from '../../styles/buttons';
import { withI18n } from '@lingui/react';

const JoinUsSection = ({ i18n }) => {
    const ctxLang = useContext(LangContext);
    return (
        <>
            <JoinUsWrapper>
                <TopHeading>
                    <TransHTML id={t`home.page.join.us.title`} />
                </TopHeading>

                <JoinUsContainer>
                    <ImgBlock alt='Contact our team' src={i18n._(t`home.page.joinUsSection.image`)} />
                    <JoinUsText>
                        <Heading>
                            <TransHTML id={t`home.page.join.us.subtitle`}/>
                        </Heading>

                        <SubHeading>
                            <TransHTML id={t`home.page.join.us.text`}/>
                        </SubHeading>

                        <CustomYellowButton href={getPath(paths.join_us, ctxLang.state.country)}>
                            <Trans id='global.join_us.leadForm.submit' />
                        </CustomYellowButton>
                    </JoinUsText>

                </JoinUsContainer>

                <CustomYellowButtonTablet href={getPath(paths.personal, ctxLang.state.country)}>
                    <Trans id='global.join_us.leadForm.submit' />
                </CustomYellowButtonTablet>

            </JoinUsWrapper>

        </>
    )
};

export default withI18n()(JoinUsSection);

const CustomYellowButton = styled(YellowButton)`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.5px;
    display: block;
    max-width: 275px;
    display: none;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: block;
    }
`;

const CustomYellowButtonTablet = styled(YellowButton)`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.5px;
    display: block;
    max-width: 275px;
    margin: 1rem auto 0 auto;

    @media (min-width: ${tabletBreakpoint}) {
        margin: 4rem auto 0 auto;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        display: none;
    }
`;

const JoinUsWrapper = styled.section`
    padding: 1.5rem 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 1rem 3rem 0;
        padding-top: 4rem;
    }
`;

const JoinUsText = styled.div`
    width: 100%;

    @media (min-width: ${tabletBreakpoint}) {
        width: 67%;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        width: 50%;
    }
`;

const JoinUsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    max-width: 990px;
    width: 100%;
    border-radius: 16px;
    margin: 0 auto 1rem auto;
    position: relative;
    padding-left: 0;

    @media (min-width: ${tabletBreakpoint}) {
        flex-direction: unset;
        align-items: center;
    }
`;

const ImgBlock = styled.img`
    width: 50%;
    background-position-x: calc(50% - 5px);
    object-fit: contain;
    height: 470px;
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
        display: block;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        height: 560px;
    }
`;

const TopHeading = styled.h2`
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 2rem;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: 3rem;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        line-height: 1.2;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: 400px;
        margin-bottom: 2rem;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;

@media (min-width: ${smallScreenBreakpoint1064px}) {
    max-width: 400px;
    }
`;




