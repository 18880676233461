import { t } from '@lingui/macro';
import {MerchantReviewItemType} from "~components/reviews/types";

export const merchantReviews: MerchantReviewItemType[] = [
    {
        id: 1,
        userName: 'Raclette U Sýráku',
        userJob: t`reference.raclette_syraky.category`,
        userComment: t`reference.raclette_u_syraku.name`,
        fbLink: 'https://www.facebook.com/USyraku/',
        instLink: 'https://www.instagram.com/usyraku',
        userImg: '/static/images/home-page/syraku.png',
        stars: 5,
    },
    {
        id: 2,
        userName: 'Bockem',
        userJob: t`reference.bockem.category`,
        userComment: t`reference.bockem.name`,
        fbLink: 'https://www.facebook.com/jsmebockem/',
        instLink: 'https://www.instagram.com/jsmebockem',
        userImg: '/static/images/home-page/bokem.png',
        stars: 5,
    },
    {
        id: 3,
        userName: 'ČÁRY a ČRTY',
        userJob: t`reference.cary_a_cary.category`,
        userComment: t`reference.cary_a_cary.name`,
        fbLink: 'https://www.facebook.com/cary.a.crty',
        instLink: 'https://www.instagram.com/cary_a_crty/',
        userImg: '/static/images/home-page/cary.png',
        stars: 5,
    },
    {
        id: 4,
        userName: 'Nonna Mona',
        userJob: t`reference.nona_mona.category`,
        userComment: t`reference.nona_mona.name`,
        fbLink: 'https://www.facebook.com/NonnaMonaLifeStyle',
        instLink: 'https://www.instagram.com/nonnamonalifestyle/',
        userImg: '/static/images/home-page/good-user4.jpg',
        stars: 5,
    },
];
