import React, { useContext } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { withI18n } from '@lingui/react';
import { Section } from '~/styles/welcome';
import { paths } from '~/../shared/routing';
import { getPath } from '~/../shared/utils';
import { LangContext } from '~/components/../providers/LangProvider';
import TransHTML from '~/components/transHTML';
import { t } from '@lingui/macro';
import { YellowButton } from '../../styles/buttons';
import { smallScreenBreakpoint1064px, tabletBreakpoint, mobileBreakpoint } from '~/styles/common';

function Homepage({ i18n }) {
    const ctxLang = useContext(LangContext);
    return (
        <HomepageWrapper id='welcome'>
            <HomePageContainer>
                <LeftImage alt='Trisbee app' src={i18n._(t`home.page.image`)} />

                <RightSection>
                    <HomePageTopHeading>
                        <TransHTML id={t`home.page.title`} />
                    </HomePageTopHeading>

                    <HomePageSubHeading>
                        <TransHTML id={t`home.page.subtitle`}/>
                    </HomePageSubHeading>

                    <CenterImage alt='Trisbee app' src={i18n._(t`home.page.image`)} />

                    <Partners>
                        <Partner alt='BTB' src='/static/images/home-page/partners/btb.svg' />
                        <Partner alt='Při Točně' src='/static/images/home-page/partners/pri-tocne.svg' />
                        <Partner alt='Limo Gang' src='/static/images/home-page/partners/web-header.svg' />
                        <Partner alt='Povaleč' src='/static/images/home-page/partners/website.svg' />
                    </Partners>

                    <CustomYellowButton href={getPath(paths.join_us, ctxLang.state.country)}>
                        <Trans id='global.pricing.signUp' />
                    </CustomYellowButton>
                </RightSection>
            </HomePageContainer>
        </HomepageWrapper>
    );
}

export default withI18n()(Homepage);

const Partners = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.3rem;
    margin: 1.5rem 0;
    flex-wrap: wrap;

    @media (min-width: ${tabletBreakpoint}) {
        display: none;
    }
`;

const Partner = styled.img`
`;

const HomePageContainer = styled.div`
    max-width: 100%;
    height: 100%;
    justify-content: center;
    background-color: white;
    padding: 3rem 1rem;
    max-width: 990px;
    margin: 0 auto;

    @media (min-width: ${tabletBreakpoint}) {
        display: flex;
        gap: 2rem;
    }
`;

const RightSection = styled(Section)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: auto;
        text-align: left;
        max-width: 750px;
        line-height: 1;
        justify-content: start;
        align-items: flex-start;
        margin-top: 3rem;
    }
`;

const HomePageTopHeading = styled.h1`
    font-size: 2rem;
    max-width: 400px;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    color: #1A1A1A;
    margin-bottom: 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        text-align: left;
        font-size: 2.8rem;
        max-width: 650px;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3.3rem;
        margin-bottom: 2rem;
    }
`;

const HomePageSubHeading = styled.p`
    max-width: 148px;
    width: 100%;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 300;

    @media (min-width: ${tabletBreakpoint}) {
        max-width: 500px;
        font-size: 1.3rem;
        font-weight: 400;
    }
`;

const CenterImage = styled.img`
    background-size: contain;
    display: flex;
    height: 376px;
    min-width: 215px;
    background-position: center;
    margin: 0 auto 1rem auto;
    object-fit: scale-down;

    @media (min-width: ${tabletBreakpoint}) {
       display: none;
    }
`;

const LeftImage = styled.img`
    background-size: contain;
    display: flex;
    height: 376px;
    min-width: 215px;
    margin-right: 2rem;
    background-position: center;
    margin: 0 auto;
    object-fit: scale-down;
    display: none;

    @media (min-width: ${tabletBreakpoint}) {
       display: block;
    }
`;

const CustomYellowButton = styled(YellowButton)`
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 2;
    letter-spacing: 0.5px;
    width: 100%;
    order: 1;

    @media (min-width: ${mobileBreakpoint}) {
        width: auto;
        order: 0;
    }
`;

const HomepageWrapper = styled.div`
    width: 100%;

    @media (min-width: ${tabletBreakpoint}) {
        position: relative;
    }
`;
