import React from 'react';
import styled from 'styled-components';
import { Trans, withI18n } from '@lingui/react';
import { t } from '@lingui/macro';
import TransHTML from '~/components/transHTML';
import { smallScreenBreakpoint1064px, tabletBreakpoint, FHDBreakpoint1920px } from '~/styles/common';
import InPageAnchor from "../InPageAnchor";
import { anchors } from "../../../shared/routing";

const HowItsWorksSection = ({ i18n }) => (
    <>
        <InPageAnchor id={anchors.business.how_it_work} />
        <HowItsWorksWrapper>
            <TopHeading>
                <Trans id='home.page.how_it_works.title' />
            </TopHeading>

            <HowItsWorksContainer>
                <ImgBlock alt='Trisbee QR' src={i18n._(t`home.page.how_it_works.image`)} />
                <HowItsWorksText>
                    <Heading>
                        <TransHTML id={t`home.page.how_it_works.subtitle`} />
                    </Heading>

                    <SubHeading>
                        <TransHTML id={t`home.page.how_it_works.text`} />
                    </SubHeading>
                </HowItsWorksText>
            </HowItsWorksContainer>
        </HowItsWorksWrapper>
    </>
);

export default withI18n()(HowItsWorksSection);

const HowItsWorksWrapper = styled.section`
    padding: 1.5rem 1rem;

    @media (min-width: ${tabletBreakpoint}) {
        padding: 3rem 1rem;
    }
`;

const HowItsWorksText = styled.div`
    @media (min-width: ${tabletBreakpoint}) {
        width: 50%;
        padding-top: 5rem;
    }
`;

const HowItsWorksContainer = styled.div`
    display: flex;
    max-width: 990px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${tabletBreakpoint}) {
        justify-content: space-between;
        align-items: center;
        align-items: flex-start;
        width: 95%;
        gap: 2rem;
        flex-direction: unset;
    }
`;

const ImgBlock = styled.img`
    width: 100%;
    background-position-x: center;
    height: 400px;
    object-fit: contain;
    margin-bottom: 2rem;

   @media (min-width: ${tabletBreakpoint}) {
        background-position-x: right;
        width: 50%;
        height: 452px;
        margin-bottom: 0;
    }

    @media (min-width: ${FHDBreakpoint1920px}) {
        height: 500px;
        transform: scale(1);
    }
`;

const TopHeading = styled.h2`
    text-align: center;
    font-size: 2rem;
    max-width: 400px;
    margin: 0 auto 2rem auto;
    color: #1A1A1A;
    font-weight: bold;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        font-size: 3rem;
        margin-bottom: 3rem;
        max-width: unset;
    }
`;

const Heading = styled.h3`
    line-height: 1.6;
    font-weight: 600;
    font-size: 1.3rem;

    @media (min-width: ${tabletBreakpoint}) {
        margin-bottom: 2rem;
    }

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: 400px;
    }
`;

const SubHeading = styled.div`
    line-height: 1.8;
    font-size: 1.2rem;
    font-weight: 400;

    @media (min-width: ${smallScreenBreakpoint1064px}) {
        max-width: 400px;
    }
`;




